<template>
  <div class="content">
    <base-snack-bar
      v-model="snackbar.show"
      :message="snackbar.message"
    />
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
    >
      <crud-cadastro
        :loading="loading"
      >
        <v-card
          class="px-6 pt-4"
          style="margin-top: 14px; border-radius: 16px"
          outlined
        >
          <v-card-title
            class="pa-0"
            style="text-shadow: grey 0.1em 0.1em 0.2em;"
          >
            Dados
          </v-card-title>
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="nome"
                label="Nome/Descrição*"
                :rules="[rules.required, rules.maxLength(45)]"
                maxlength="45"
                required
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="sigla"
                label="Sigla*"
                counter="10"
                :rules="[rules.required, rules.maxLength(10)]"
                maxlength="10"
                validate-on-blur
                outlined
                rounded
                dense
              />
            </v-col>
          </v-row>
        </v-card>
        <v-row
          class="pa-3"
          justify="center"
          justify-sm="end"
        >
          <v-btn
            class="text-none text-white mr-3"
            color="error"
            rounded
            @click="$router.push({ path: `/${$user.get().role}/cdc/unidadesmedida` })"
          >
            <v-icon
              dark
              left
            >
              mdi-minus-circle
            </v-icon>
            Cancelar
          </v-btn>
          <v-btn
            color="success"
            :disabled="desabilitaBotao"
            class="text-none text-white"
            rounded
            @click="submitForm"
          >
            <v-icon
              dark
              left
            >
              mdi-checkbox-marked-circle
            </v-icon>
            Salvar
          </v-btn>
        </v-row>
      </crud-cadastro>
    </v-form>
  </div>
</template>
<script>
  import { mapFields } from 'vuex-map-fields'
  import rules from '../../utils/formRules'
  import UnidadeFormStore, { BOOTSTRAP_PROFILE, SUBMIT } from '@/store/modules/forms/unidade'
  import { mapState, mapActions } from 'vuex'
  export default {
    data () {
      return {
        valid: false,
        rules: rules,
        snackbar: { message: '', show: false },
      }
    },
    computed: {
      ...mapState('form/unidade', ['loading', 'isEditing']),
      ...mapFields('form', [
        'data.nome',
        'data.sigla',
      ],
      ),
      desabilitaBotao () {
        return this.loading || !this.valid
      },
    },
    created () {
      if (!this.$store.state.form.unidade) { this.$store.registerModule(['form', 'unidade'], UnidadeFormStore) }
      const id = this.$route.query.id
      this.BOOTSTRAP_PROFILE({ id })
    },
    methods: {
      ...mapActions('form/unidade', [BOOTSTRAP_PROFILE, SUBMIT]),
      submitForm () {
        if (!this.$refs.form.validate()) {
          this.snackbar = {
            show: true,
            message: 'Existem campos Obrigatórios não prenchidos. Por favor, preencha-os!',
          }
          return null
        }
        this.SUBMIT({ router: this.$router, user: this.$user })
      },
    },
  }
</script>

export class Unidade {
  constructor ({
    id,
    nome,
    sigla,
  } = {
    id: '',
    nome: '',
    sigla: '',
  }) {
    this.id = id
    this.nome = nome
    this.sigla = sigla
  }
}

export function createUnidade (data) {
  return Object.freeze(new Unidade(data))
}
